import React, { useEffect, useRef } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Button } from 'rootnet-ui';
import gd from '../../base/global';

export default function Demo(props) {
    const sock = useRef();

    function handleMessage(message) {
        console.log("received:", message);
    }

    useEffect(() => {
        sock.current = Stomp.over(new SockJS(`api/webserver?uuid=${gd.User.uuid}`));
        sock.current.connect({}, () => {
            console.log("connected");
            sock.current.subscribe('/topic/greetings', handleMessage);
        })

        return () => {
            if (sock.current) {
                sock.current.disconnect();
                sock.current = null;
                console.log("disconnected");
            }
        }
    }, [])

    function handleClick() {
        if (sock.current)
            sock.current.send("/app/hello", {}, JSON.stringify({ name: "HELLO" }));
    }

    return <div>
        WEBSOCKET DEMO
        <Button primary onClick={handleClick}>SEND</Button>
    </div>
}