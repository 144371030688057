import React from 'react';
import classnames from 'clsx';
import './Icon.scss';

export function Icon(props) {
    const icon_name = props.name ? props.name : "help";
    if (props.symbol) {
        return <svg className="symbol-icon" aria-hidden="true" onClick={props.onClick} style={props.style}>
            <use xlinkHref={`#icon-${icon_name}`}></use>
        </svg >
    }
    const cn = classnames("icon", "iconfont", `icon-${icon_name}`, props.className, { rotate: props.rotate });
    return <i className={cn} style={props.style} onClick={props.onClick} />
}

export default Icon;