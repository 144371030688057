import _ from "lodash";
class CacheData {
    _cache = new Map();
    get(key, defaultValue = undefined) {
        if (!this._cache.has(key) && defaultValue !== undefined)
            this._cache.set(key, defaultValue)
        return this._cache.get(key);
    }
    set(key, value) {
        if (value === undefined)
            this._cache.delete(key);
        else
            this._cache.set(key, value);
    }
    updateTo(key, value) {
        const v = this.get(key, {});
        _.assign(v, value);
        return true;
    }
    updateFrom(key, value) {
        if (_.isObject(value)) {
            const v = this.get(key);
            if (v) {
                _.assign(value, v);
                return true;
            }
        }
        return false;
    }
    clear() {
        this._cache.clear();
    }
}
class GlobalData {
    _user = null;
    _storage = sessionStorage;

    get User() {
        if (this._user === null) {
            const str = this._storage.getItem('current_user');
            if (str)
                this._user = JSON.parse(str);
        }
        return this._user;
    }
    set User(user) {
        if (user) {
            this._storage.setItem('current_user', JSON.stringify(user))
            this._user = { ...user };
        }
        else {
            this._storage.removeItem('current_user');
            this._user = null;
        }
    }

    _cache = new CacheData();

    get Cache() {
        return this._cache;
    }
}

const gd = new GlobalData();
export default gd;
const cache = gd.Cache;
export { cache as Cache };