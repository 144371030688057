import _ from 'lodash';
import {isNil} from 'rootnet-core/format'

/*
@author mw && ylf
@params {a:1,b:2} => 'a=1&b=2'
*/
export function strParams(params) {
    if (!_.isObject(params)) return '';
    const paramsArray = [];
    _.mapKeys(params, (value, key) => {
        !_.isNil(value) && paramsArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    });
    return paramsArray.join('&')
}

//组合方法--ylf
export function compose(...funcs) {
    if (funcs.length === 0) return arg => arg;
    if (funcs.length === 1) return funcs[0];
    return funcs.reduce((a, b) => (...args) =>  a(b(...args)))
}

/*
@author mw && ylf
@params  'a=1&b=2' => {a:1,b:2}
*/
export function parseParams(strParams) {
    if (!_.isString(strParams)) return {};
    return strParams.split('&').reduce((acc, e) => {
        let [key,value] = e.split('=');
        if(value==='true') value=true;
        if(value==='false') value=false;

        acc[decodeURIComponent(key)] = decodeURIComponent(value);
        return acc
    }, {})
}

// wll
// xxx:3000/#/a/b/c?d=1&e=2&f=3 => {d:1,e:2,f:3}
export function pathSearchFor(path) {
    if(isNil(path)) return {}
    return _.reduce(path.slice(1).split('&'),(result, item)=>{
        const splitList = item.split('=')
        result[splitList[0]]=decodeURIComponent(splitList[1])
        return result
    },{})
}

// ?d=1&e=2&f=3 => {d:1,e:2,f:3}
export function pathParamsFor(path) {
    return compose(parseParams, _.last, _.split)(path, '?')
}

//any=>Function
export function Fn(f,defaultValue=()=>{}) {
    return _.isFunction(f) ? f : defaultValue;
}

//any=>Array
export function Arr(a,defaultValue=[]) {
    return _.isArray(a) ? a : defaultValue;
}

//any=>Object
export function Obj(o,defaultValue={}) {
    return _.isObject(o) ? o : defaultValue;
}

//检测带单位的字符
export const unitReg = new RegExp(/^([\d.,]+)([A-Z%]+)$/);

//捕获数字单位
export function unitFor(v) {
    if (!unitReg.test(v)) return;
    return unitReg.exec(v)[2];
}

//[{header:xxx}]=>[{text:xxx}]
export function transHeaderToText(options) {
    if (!_.isArray(options)) return [];
    options.forEach(x=>{if(x.header) x.text=x.header;});
}

//ylf
export function historyParamsFor(p) {
    if(!_.isObject(p)) return p;
    p.pageNum = Number(p.pageNum);
    p.pageSize = Number(p.pageSize);
    return p;
}

//DataGrid--summary属性使用
export function summaryFor(opts, data) {
    if (!_.isArray(opts) || !_.isArray(data)) return [];
    return _.cloneDeep(opts).map(a => {
        a.colSpan = 1;
        a.header = '-';
        if (a.isTotal) {
            const value = data.reduce((acc, b) => acc + b[a.bind], 0);
            a.header = () => _.isFunction( a.convert)?a.convert((value)):value;
        }
        return a;
    });
}

export function addSummaryFirst(list) {
    list.forEach((x, i) => { if (i === 0) x.header = '汇总' });
    return list;
}
export const summaryWithHeader = compose(addSummaryFirst, summaryFor);