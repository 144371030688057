import React, {useCallback, useEffect, useMemo} from 'react';
import {DataGrid, Dialog} from 'rootnet-ui';
import './AutoTestReportList.scss'
import _ from "lodash";
import {useGet} from "rootnet-biz/lib/hooks";
import OperationList from "../../../../../../../project_share/components/OperationList";

function getColumns(props){
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center',stretch: false, width: 40 },
        { header: '报告ID', bind: 'reportId', width: 160, sortable: true, tooltip: true},
        { header: '任务名称', bind: 'reportName', width: 160, sortable: true, tooltip: true},
        { header: '执行结果', bind: 'reportResult', width: 160, sortable: true, tooltip: true},
        { header: '执行时间', bind: 'reportDate', width: 160, sortable: true, tooltip: true},
        { header: '来源', bind: 'reportForm', width: 160, sortable: true, tooltip: true},
        { header: '操作', bind: '', align: 'center', width: 120, isTransfer: false, convert: renderOperation }
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '预览',
                onClick: ()=>{
                    window.open(o?.reportUrl, '_blank')
                }
            }
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    fixedRight: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});
function AutoTestReportList(props) {
    const {close, versionId} = props
    const {data: list, doFetch: getList, loading: listLoading} = useGet()

    const refreshList = useCallback(()=>{
        if(_.isNil(versionId)) return
        getList('/devops/ubs/common/autotestReportList?releaseName='+versionId)
    },[getList, versionId])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const options = useMemo(()=>{
        return getOptions(getColumns({}))
    },[])

    return <Dialog header={'自动化测试报告查询'} className={'auto-test-report-list'} contentClassName={'flex-y'} cancel={close}>
        <DataGrid option={options} data={list} loading={listLoading}/>
    </Dialog>
}

export default AutoTestReportList;