import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash'
import cls from 'clsx';
import { Tooltip } from 'antd'
import { MenuExt } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/lib/hooks';
import { Cache } from "../base/cache";
import { Icon } from '../components/Icon';
// import { MenuExt as Menu } from './common/menu';

const PC_HOME_PAGE = '/workplace'
// const PC_HOME_PAGE = '/versionMsg'

function generate_menu(data) {
    if (_.isEmpty(data)) return []
    const listMenu = JSON.parse(JSON.stringify(data))
    _.forEach(listMenu, item => delete item.children)
    const map = {}
    _.forEach(listMenu, item => map[item.funcCode] = item)
    const newListMenu = []
    _.forEach(listMenu, r => {
        r.to = r.url;
        r.text = r.funcName;
        r.expanded = false;
        r.icon = _.isNil(r.icon) ? 'huawen' : r.icon
        let parents = map[r.pid]
        if (parents) {
            ((parents.children) || (parents.children = [])).push(r)

        } else newListMenu.push(r)
    })
    return newListMenu
}

function getMenuList(menuList) {
    if (_.isEmpty(menuList)) return []
    let urlList = []
    _.forEach(menuList, x => getUrl(x))
    return urlList

    function getUrl(menuItem) {
        const children = _.get(menuItem, 'children')
        if (_.isEmpty(children)) return urlList.push(_.get(menuItem, 'url'))
        return _.map(children, x => getUrl(x))
    }

}

const FLAG = "left_menu_wide";

const GET_USER_URL = () => ([
    `/common/uacUserRole`,
])

function MenuIcon(props) {
    const { name, onClick, style } = props
    const icon_name = name ? name : "help";
    return <svg className="symbol-icon" aria-hidden="true" onClick={onClick} style={style}>
        <use xlinkHref={`#icon-${icon_name}`}></use>
    </svg >
}

export function LeftMenu(props) {//setCurrentMenu
    const { history, menuData, currentMenu, setCurrentMenu, onMenuItemClick } = props
    const item = localStorage.getItem(FLAG);
    const [data, setData] = useState([])
    const [wide, setWide] = useState(item === "1")
    // const [defaultPutAway, setDefaultPutAway] = useState(false)
    const { data: userRes, doFetch } = useGet()

    useEffect(() => {
        const nowCurrentMenu = _.get(_.find(menuData, o => o.url === history.location.pathname), 'groupName')
        setCurrentMenu(x => {
            if (x === nowCurrentMenu) return x
            return nowCurrentMenu
        })
    }, [menuData, history, setCurrentMenu])

    const toggle = useCallback((flag, defaultPutAway = false) => {
        setWide(flag)
        // if (defaultPutAway) setDefaultPutAway(defaultPutAway)
        localStorage.setItem(FLAG, flag ? "1" : "0")
    }, [])

    useEffect(() => {
        const url = GET_USER_URL()
        doFetch(url)
    }, [doFetch])

    useEffect(() => {
        if (_.isEmpty(menuData) || _.isEmpty(userRes)) return
        const currentUrl = '/' + window.location.hash.split('?')[0].split('/')[1]
        const menuList = getUserMenu(_.cloneDeep(menuData), currentMenu, userRes)
        const urlList = getMenuList(menuData)
        Cache.set('urlList', urlList)
        if (menuList) setData(menuList);
        if (!_.isEmpty(urlList) && !_.includes(urlList, currentUrl)) history.push(PC_HOME_PAGE)
    }, [currentMenu, menuData, history, userRes])

    return (
        <div className={cls("left-menu")}>
            <Tooltip title={wide ? '收起' : '展开'}>
                <div
                    className='menu-logo'
                    onClick={() => toggle(wide ? false : true)}
                    style={{ cursor: 'pointer' }}
                >
                    <Icon
                        style={{ cursor: 'pointer' }}
                        name={wide ? 'tiaojianshouqi' : 'tiaojianzhankai'}
                    />
                </div>
            </Tooltip >
            <MenuExt
                className='menu-list'
                autoCollapse={true}
                wide={wide}
                iconType={MenuIcon}
                data={data}
                itemClick={onMenuItemClick}
            />
            {/* <Menu
                defaultPutAway={defaultPutAway}
                open={toggle}
                className='menu-list'
                autoCollapse={true}
                wide={wide}
                iconType={Icon}
                data={data}
                itemClick={onMenuItemClick}
            /> */}
        </div>
    )
}

export function getUserMenu(userMenu, currentMenu, res) {
    const getData = _.get(_.groupBy(userMenu, 'groupName'), currentMenu)
    const sortUserMenu = _.orderBy(getData, 'order', 'asc')
    const userPositionList = _.map(res[0], x => x.posId)
    localStorage.setItem('userPositionList', JSON.stringify(userPositionList))
    const costCenterRightList = ['OPS', 'IT', 'Manager', 'PM', 'PMO'];
    if (_.isArray(sortUserMenu) && sortUserMenu.length > 0) {
        const showMenu = _.filter(sortUserMenu, x => x.funcType === 0)
        if (_.intersection(userPositionList, costCenterRightList).length > 0) return addUserInfo(showMenu);
        return addUserInfo(_.filter(showMenu, x => x.funcName !== '成本中心管理'));
    }
    return addUserInfo(null);

    function addUserInfo(x) {
        return generate_menu(x)
    }
}
