import React, { useMemo } from 'react'
import _ from 'lodash'
import { Loader } from 'rootnet-ui'
import DevProjectScopeVersion from '../devProjectScope/devProjectScopeVersion/DevProjectScopeVersion'
import TaskVersion from './taskVersion'
import { selectOption, useFuncCode } from '../../../../common/commonMethod'
import gd from '../../../../../base/global'
import './index.scss'

export default function DevProjectTask(props) {
  const { versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
    projectMap, versionListRes, versionLoading, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow: isDevProjectShow, projectId, projectName } = props

  const selectedVersion = useMemo(() => {
    if (_.includes(['no-plan-version', 'history-defect', 'planned-version'], _.get(selectedVersionItem, 'releaseID'))) return null
    return _.get(selectedVersionItem, 'releaseID')
  }, [selectedVersionItem])

  const versionOption = useMemo(() => {
    if (_.isEmpty(_.get(versionListRes, 'releaseInfoList', []))) return []
    return selectOption(_.get(versionListRes, 'releaseInfoList', []), ['releaseName', 'releaseID'])
  }, [versionListRes])

  const [isReqCode, isProjectCode, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck] = useFuncCode(['081015', '081020', '1503', '1902', '1910'])

  const { state, functionary: principal, poUser, snowId, mulCustID } = useMemo(() => selectedVersionItem || {}, [selectedVersionItem])

  const isShow = useMemo(() => !(_.isNil(selectedVersionItem) || _.includes(['no-plan-version', 'planned-version'], _.get(selectedVersionItem, 'releaseID'))), [selectedVersionItem])

  const isClient = useMemo(() => (_.isNil(selectedVersionItem) || _.includes(['no-plan-version', 'planned-version'], _.get(selectedVersionItem, 'releaseID'))), [selectedVersionItem])

  const isUnplanned = useMemo(() => {
    return _.get(selectedVersionItem, 'releaseID') === 'no-plan-version'
  }, [selectedVersionItem])

  const isDisabled = useMemo(() => {
    return getDisabled({ state, principal, poUser, isReqCode, isProjectCode })
  }, [state, principal, poUser, isReqCode, isProjectCode])

  const releaseIds = useMemo(() => {
    if (_.isNil(selectedVersion) || _.includes(['no-plan-version', 'history-defect', 'planned-version'], selectedVersion)) {
      return _.join(_.map(_.get(versionListRes, 'releaseInfoList', []), 'releaseID'), ',')
    }
    return selectedVersion
  }, [selectedVersion, versionListRes])

  const isDisplay = useMemo(() => {
    return _.isNil(selectedVersion) || _.includes(['no-plan-version', 'history-defect'], selectedVersion)
  }, [selectedVersion])

  return (
    <div className='dev-project-task flex fill'>
      <DevProjectScopeVersion
        projectId={_.get(projectMap, 'projectId')} activeModule={'version'}
        {...{
          versionLoading, selectedVersionItem, setSelectedVersionItem,
          refreshVersionList, versionListRes, versionStatusOptions, addVersionAuth, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow: isDevProjectShow
        }}
      />
      {
        (_.isNil(versionListRes) && versionLoading) && <div style={{ flex: 1 }}><Loader fill /></div>
      }
      {
        (!_.isNil(versionListRes) && !versionLoading) && <TaskVersion currentSelect={_.get(selectedVersionItem, 'releaseID')} {...{ releaseIds, allUserRes, isReqCode, isProjectCode, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, isDisabled, principal, poUser, snowId, isShow, isUnplanned, projectId, projectName, isDisplay, mulcustId: _.compact(_.split(mulCustID, '^')), isClient, versionOption }} />
      }
    </div>
  )
}

export function getDisabled({ poUser, state, principal, isReqCode, isProjectCode, principals }) {
  if (_.includes(['R', 'L'], state)) return true
  else if (_.includes(['T'], state) && (poUser === gd.User.operator_id || principal === gd.User.operator_id || isReqCode || isProjectCode || _.includes(principals, gd.User.operator_id))) {
    return false
  } else if (_.includes(['C'], state) && (poUser === gd.User.operator_id || principal === gd.User.operator_id || isReqCode || _.includes(principals, gd.User.operator_id))) {
    return false
  }
  return true
}

