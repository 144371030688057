import React, { useEffect, useMemo, useState, useReducer, useCallback, useContext } from 'react'
import _ from 'lodash'
import {Badge, Tooltip as AntdTooltip} from 'antd'
import { DataGrid, Tooltip, Pagination } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Box } from '../../../../../common/commonComponent'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import DisplayForm from '../../../../../testMgt/testPlan/components/displayForm'
import useGetViewConfig from '../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../common/view/hooks/useRefreshList'
import findConvert from '../../../../../common/view/findConvert'
import useGetIdList from '../../../../../common/view/hooks/useGetIdList'
import useGetAppendData from '../../../../../common/view/hooks/useGetAppendData'
import convertTableAlign from '../../../../../common/view/convertTableAlign'
import useGetDateOptions from '../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../common/view/hooks/useGetTreeList'
import gd from '../../../../../../base/global'
import { GuideContext } from '../../../../../common/Context'
import { Icon } from '../../../../../../components'
import ViewArea from '../../../../../common/view/ViewArea'
import DelMessage from '../../../../../../components/DelMessage'
import './index.scss'
import AutoTestReportList from "./autoTestReportList/AutoTestReportList";

const DEL_URL = '/test/plan/delete'
// const LIST_URL = '/test/plan/list'

const getColumns = (props) => {
  const { fieldList, convertCollection, setMode, appendData, dateOptions } = props
  const customColumns = [
    { header: '结果分布', bind: 'distributionOfResults', width: 180, tooltip: true, convert: (v, o) => (caseResultVoList(o)), class: 'progress-color' },
    { header: '已测用例', bind: 'testedCases', width: 80, tooltip: true, align: 'center', convert: (v, o) => measured(o) }
  ]

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return _.find(customColumns, x => x.bind === fieldItem.columnId) || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    switch (fieldItem.fieldId) {
      case 'planName':
        return <>
          <span className='enter-detail-field' onClick={() => {
            window.open(`#/testPlanMgt?planId=${o.id}`)
          }}>
            {findConvert(fieldItem, v, convertCollection, dateOptions) || '-'}
          </span>
          <span className='edit-icon'>
            <AntdTooltip title='编辑' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon name='bianji2' onClick={() => setMode({ modes: 'edit', data: o })} />
              </span>
            </AntdTooltip>

            <AntdTooltip title='删除' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon
                  name='shanchu2'
                  className='del-icon'
                  onClick={() => setMode({ modes: 'delete', planId: o.id })}
                />
              </span>
            </AntdTooltip>
          </span>
        </>
      default:
        return findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
    }

  }

  function measured(o) {
    const findData = _.find(appendData, item => item?.id === o?.id)
    const { rate } = findData?.object || {}
    return rate
  }

  function caseResultVoList(o) {
    const findData = _.find(appendData, item => item?.id === o?.id)
    const { blocking, fail, failed, skip, through, unenforced, throughed, fai, skiped, blockinged, unableCount, unableCounted } = findData?.object || {}
    return <div className='progressSet'>
      {through !== '0%' && <Progress width={cutStr(through)} fraction={throughed} color='#50C398' name='通过' />}
      {fail !== '0%' && <Progress width={cutStr(fail)} fraction={fai} color='#EA5252' name='失败' />}
      {blocking !== '0%' && <Progress width={cutStr(blocking)} fraction={blockinged} color='#F3B433' name='阻塞' />}
      {failed !== '0%' && <Progress width={cutStr(failed)} fraction={unenforced} color='#898688' name='未执行' />}
      {skip !== '0%' && <Progress width={cutStr(skip)} fraction={skiped} color='#C8C5C7' name='跳过' />}
      {unableCount !== '0%' && <Progress width={cutStr(unableCount)} fraction={unableCounted} color='#000000' name='无法执行' />}
    </div>
  }

  function Progress(props) {
    const { width, fraction, color, name } = props
    const style = {
      width: 8,
      height: 8,
      marginRight: 4,
      borderRadius: 4,
      background: color,
      display: 'inline-block',
    }
    const DOM = <div className='tips-style'>
      <div>
        <span style={style}></span>
        {width} {name}</div>
      <div style={{ marginLeft: 12 }}>{fraction}用例</div>
    </div>

    return <Tooltip title={DOM}>
      <div className='progressColor' style={{ width: width, height: 6, background: color }} />
    </Tooltip>
  }

  function cutStr(str) {
    return str
    // return str && Math.round(str.substr(0, _.size(str) - 1) * 1) + '%'
  }
}

let sortConfig = {}

const getOptions = (columns) => ({
  columns,
  autoFill: true,
  virtualized: false,
  resizable: true,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
})

const conditional = () => ({
  sort: sortConfig,
  onSort: (data, sort) => {
    sortConfig = sort
    return _.orderBy(data, x => x[sort.column], sort.direction)
  }
})

const FUNC_CODE = '082000'

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function VersionTestPlan(props) {
  const { versionId, isShow } = props
  const [params, setParams] = useState(getInitParams())
  const [currentId, setCurrentId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { pageSize, total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [showAutoTestDialog, setShowAutoTestDialog] = useState(false)

  const {data: autoTestList, doFetch: getAutoTestList} = useGet()

  const refreshAutoTestList = useCallback(()=>{
    if(_.isNil(versionId)) return
    getAutoTestList('/devops/ubs/common/autotestReportList?releaseName='+versionId)
  },[getAutoTestList, versionId])

  useEffect(()=>{
    refreshAutoTestList()
  },[refreshAutoTestList])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const { setGuideList } = useContext(GuideContext)
  const { doFetch: showGuide } = useGet()

  useEffect(() => {
    showGuide('/promotion/guide?funcCode=22').then(res => {
      if (res) {
        setGuideList(x => _.concat(x, [
          {
            element: document.querySelector('.view-text-wrap'),
            intro: '视图：可以通过配置个人视图查看自己需要的信息。' +
              '支持自定义列表显示字段，配置字段条件，视图之间可以自由切换'
          }
        ]))
      }
    })
  }, [showGuide, setGuideList])
  const dateOptions = useGetDateOptions()

  const replaceParams = useMemo(() => {
    return ({ 'test_plan.related_releaseId': versionId, })
  }, [versionId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const refresh = useCallback((replaceParams) => {
    refreshList(replaceParams)
    setCurrentId(_.get(gd, 'testPlanCurrentId', null))
  }, [refreshList])

  const idList = useGetIdList(list)
  const appendData = useGetAppendData(idList, '/test/plan/get/result')

  const [mode, setMode] = useState(null)

  const { options, dataGridList, TreeSwitch } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { setMode, appendData },
    optionsAppendParams: conditional()
  })

  useEffect(() => {
    if (mode?.modes === 'add') sortConfig = {}
  }, [mode])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <Badge count={_.size(autoTestList)} size="small" offset={[-20, 0]} color={'#9B9B9B'}>
        <TextIconBtn icon='moban' text='自动化测试报告' style={{marginRight: 24}} onClick={()=>setShowAutoTestDialog(true)}/>
      </Badge>
      <TreeSwitch />
      {isShow && <TextIconBtn icon='tianjia' text='新增' onClick={() => setMode({ modes: 'add' })} />}
    </div>
  }, [isShow, autoTestList])

  return (
    <div className='version-test-plan'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Table {...{ error, boxLoading, extra, list, dataGridList, options, pageSize, total, pageNum, setParams, currentId }} />
      {_.includes(['add', 'edit'], mode?.modes) && < DisplayForm refresh={refresh} close={() => setMode(null)} defaultParams={{ relatedReleaseId: versionId }} {...mode} />}

      {
        mode?.modes === 'delete' &&
        <DelMessage
          refresh={refresh}
          close={() => setMode(null)}
          url={`${DEL_URL}?planId=${mode?.planId}`}
        >
          确定删除当前内容？
        </DelMessage>
      }
      {
        showAutoTestDialog &&
          <AutoTestReportList close={()=>setShowAutoTestDialog(false)} {...{versionId}}/>
      }
    </div>
  )
}

function Table(props) {
  const { error, boxLoading, extra, list, dataGridList, options, pageSize, total, pageNum, setParams, currentId } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const onRowClick = useCallback((item) => {
    gd.testPlanCurrentId = item?.id
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return <Box className='x-card-singlegrid' title='测试计划列表' error={error} loading={boxLoading} extra={extra} data={list}>
    <DataGrid data={dataGridList} option={options} onRowClick={onRowClick} />
    <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
      onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
  </Box>
}
